import React, { Fragment } from 'react'
import useMedia from 'use-media'
import Layout from '../layout'
import {
  SEO,
  Overview,
  Feature,
  More,
  Action,
  Plan,
  Image,
} from '../components'

import ReportingApiOgImage from '../assets/images/reporting-api-seo-image.png'

const DataAndReporting = () => {
  const isWide = useMedia({ minWidth: 870 })
  return (
    <Layout>
      <SEO
        title="LiveChat Platform – Data & Reporting APIs"
        description="Build customer service reports that give the answers"
        image={ReportingApiOgImage}
      />
      <div className="u-bg-black-3">
        <Overview
          title="Data & Reporting APIs"
          subtitle={
            <Fragment>
              Build reports that <br /> give the answers
            </Fragment>
          }
          image={{
            name: 'dataAndReportingOverview',
            maxHeight: 430,
          }}
          line={{ position: 'left', rotate: 25 }}
          features={[
            {
              title: 'Chat Metrics',
              description:
                'Pull data on chat duration, response times, CSAT, and a variety of typical customer care metrics.',
              externalLink: `${
                process.env.GATSBY_DOCS_URL
              }data-reporting/reports-api/`,
            },
            {
              title: 'Custom Properties',
              description:
                'If native LiveChat metrics are not enough, you can easily build all variety of metrics using custom properties.',
              externalLink: `${
                process.env.GATSBY_DOCS_URL
              }data-reporting/reports-api/`,
            },
            {
              title: 'Data-driven Flows',
              description:
                'Create automation flows that are driven fully by data. Automate queue limits, tagging and many other actions.',
              externalLink: `${
                process.env.GATSBY_DOCS_URL
              }data-reporting/reports-api/`,
            },
          ]}
          button={{
            label: 'See Reports API Docs',
            link: `${process.env.GATSBY_DOCS_URL}data-reporting/reports-api/`,
          }}
        />
      </div>
      <div className="u-bg-black-3 u-Pt-2xl u-Pb-xs">
        <Feature
          marginBottom
          title={
            <>
              Dashboards &amp; reports <br />
              with CSAT metrics
            </>
          }
          description="Pull standard customer service data and mash them together into very specific metrics. Create custom reports that meet individual use cases perfectly."
          image="csat"
        />
        <Feature
          marginBottom
          inverse
          title={<>Powerful APIs to build custom metrics</>}
          description="Every bit of data is accessible via APIs. Also, every bit of data may be enriched with a specific property. Count the occurrences of certain words, calculate the agent loads or analyze the traffic trends."
          image="metrics"
        />

        <Feature
          title={
            <>
              Data-driven automations
              <br /> &amp; decison making
            </>
          }
          description="Make data work for people. Automate their jobs with scripts and routines. Equip teams and managers with precise information, so their decisions are always backed up with data."
          image="customerSdk"
        />
      </div>

      <div className="u-bg-black-3 u-Pb-2xl">
        <Plan
          title={
            <Fragment>
              Analyze Platform data <br /> with your favorite tools
            </Fragment>
          }
          subtitle={
            <Fragment>
              With LiveChat Reporting APIs and export functionalities, <br />{' '}
              you can put LiveChat data into any BI system of choice.
            </Fragment>
          }
          marginBottom="u-Mb-md"
        />
        <div className="o-container u-Mb-2xl u-flex u-justify-center">
          <Image
            type="favouriteTools"
            alt="tools"
            wrapperStyles={{
              height: '50%',
              width: '50%',
            }}
          />
        </div>
        <More
          label={
            <Fragment>
              See other
              <span className="u-text-mark u-border-red"> Platform </span>
              products
            </Fragment>
          }
          links={[
            {
              title: 'Programmable Chat',
              description:
                'Customize Agent App with multiple widgets and contextual elements.',
              link: '/programmable-chat/',
            },
            {
              title: 'Open Chat Widget',
              description:
                'Just a few easy steps to change wha but also tweak the behaviour.',
              link: '/omnichannel/',
            },
            {
              title: 'Omnichannel APIs',
              description:
                'Tie multiple communication channels with one nifty protocol.',
              link: '/omnichannel/',
            },
          ]}
        />
      </div>
      <div className="u-bg-black2 u-Py-md">
        <Action
          image="numberOfChats"
          title="Build reports that actually help your team"
          button={{
            link: process.env.GATSBY_CONSOLE_URL,
            label: isWide ? 'Open Developer Console' : 'Open Dev Console',
          }}
          link={{
            link: `${process.env.GATSBY_DOCS_URL}data-reporting/`,
            label: 'browse the docs',
          }}
        />
      </div>
    </Layout>
  )
}

export default DataAndReporting
